<template>
  <div>
    <el-card class="box-card" v-loading="loading">
      <div slot="header" class="clearfix flex_i">
        <span class="title_cadr_block">{{ $t("message.blank_head") }}</span>
        <div class="d-flex" style="float: right; padding: 3px 0">
          <el-button
            v-if="logo.length == 0"
            size="small"
            class="p-2"
            type="primary"
            @click="drawerCreate = true"
            icon="el-icon-plus"
            circle
          ></el-button>
          <el-button
            :disabled="logo.length == 0"
            size="small mr-2"
            class="p-2"
            type="success"
            @click="clickUpdate(logo)"
            icon="el-icon-edit"
            circle
          ></el-button>
          <el-button
            :disabled="logo.length == 0"
            size="small"
            class="p-2"
            @click="destroy(logo)"
            type="danger"
            icon="el-icon-delete"
            circle
          ></el-button>
        </div>
      </div>
      <!-- <div class="bordered ml-5 "  style="width:26px" shadow="always" v-loading="loading">
        <div class="content-table" v-html="logo.content"></div>
      </div> -->
      <el-card class="mt-4 a4" shadow="always">
        <div class="content-table " v-html="logo.content"></div>
      </el-card>
    </el-card>

    <!-- <el-drawer
      size="80%"
      :visible.sync="drawer.create.status"
      :ref="drawer.create.name"
      @opened="drawerOpened(drawer.create.component)"
      @closed="drawerClosed(drawer.create.component)"
    >
      <crm-create
        :ref="drawer.create.component"
        :drawer-name="drawer.create.name"
      ></crm-create>
    </el-drawer> -->

    <!-- <el-drawer
      size="60%"
      :visible.sync="drawer.update.status"
      :ref="drawer.update.name"
      @open="reopenUpdate = true"
      @opened="drawerOpened(drawer.update.component)"
      @closed="drawerClosed(drawer.update.component)"
    >
      <crm-update
        :selected="selectedModel"
        :ref="drawer.update.component"
        :drawer-name="drawer.update.name"
        @open="reopenUpdate = true"
          @c-close="closeDrawer"
          @closed="emptyModel"
      ></crm-update>
    </el-drawer> -->

    <el-drawer
      title="Новый тег"
      :visible.sync="drawerCreate"
      size="60%"
      :drawer="drawerCreate"
      @close="reloadIfChanged"
    >
      <div>
        <crm-create @c-close="closeDrawer"></crm-create>
      </div>
    </el-drawer>

    <el-drawer
      title="Изменить тег"
      :visible.sync="drawerUpdate"
      size="60%"
      :drawer="drawerUpdate"
      @open="reopenUpdate = true"
      @close="reopenUpdate = false"
      @closed="emptyModel"
    >
      <div>
        <crm-update
          :selected="selectedModel"
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
          @closed="emptyModel"
        ></crm-update>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/crm-create";
import { mapGetters, mapActions } from "vuex";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import printlogo from "@/utils/mixins/printlogo";
import drawer from "@/utils/mixins/drawer";
import { i18n } from "@/utils/i18n";

export default {
  mixins: [list, printlogo, drawer],
  name: "letterhead",
  data() {
    return {
      url: process.env.VUE_APP_URL_DOCS,
      logo: [],
      logoimg: [],
      loading: false,
      selectedModel: "",
      drawerUpdate: false,
      reopenUpdate: false,
      drawerCreate: false,
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate",
        },
      },
    };
  },
  components: { CrmCreate, CrmUpdate },
  computed: {
    ...mapGetters({
      list: "formLogo/list",
      columns: "formLogo/columns",
      pagination: "formLogo/pagination",
      filter: "formLogo/filter",
      sort: "formLogo/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapActions({
      updateList: "formLogo/index",
      updateSort: "formLogo/updateSort",
      updateColumn: "formLogo/updateColumn",
      updateFilter: "formLogo/updateFilter",
      updatePagination: "formLogo/updatePagination",
      empty: "formLogo/empty",
      houseShow: "formLogo/show",
      delete: "formLogo/destroy",
      refreshData: "formLogo/refreshData",
    }),
    clickUpdate(model) {
      this.fetchData();
      this.drawerUpdate = true;
      this.selectedModel = model;
    },
    async fetchData() {
      this.drawerUpdate = false;
      this.drawerCreate = false;
      if (!this.loading) {
        this.loading = true;
        await this.updateList()
          .then((res) => {
            this.logo = res.data.printlogo;
            this.logoimg = res.data.printlogo.images[0];
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    fetchDelete(model) {
      this.selectedModel = model;
    },
    destroy(obj) {
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.delete(obj.id)
            .then((res) => {
              this.$alert(res);
              this.loading = false;
              this.fetchData();
              this.logo = [];
              this.logoimg = [];
              this.fetchEmpty();
              this.emptyModel();
            })
            .catch((err) => {
              this.$alert(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: i18n.t("message.cancel"),
          });
        });
    },
    fetchEmpty() {
      this.empty();
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
<style lang="scss" scoped>
.my_letterhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;

  img {
    width: 300px;
  }

  ul {
    text-align: right;
    list-style: none;
    line-height: 23px;

    li {
      font-size: 16px;
      font-weight: 700;
      line-height: 30px;
    }
  }
}
.title_cadr_block {
  padding-top: 10px;
  font-size: 16px;
  font-weight: 700;
}
.flex_i {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.flex_i::after {
  content: none;
}

.a4{
     width: 220mm;
     margin: auto
}
</style>
