<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
          <div class="grid-content bg-purple">
            <div style="display: inline-block" class="title_h"></div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                size="medium"
                :loading="save_loading"
                :disabled="save_loading ? true : false"
                type="success"
                @click="saveData"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="block_Editor_Naw">
      <el-row>
        <el-col :span="24">
          <div class="documentation_content">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item :title="$t('message.page_header')" name="1">
                <div class="Editor_widht">
                  <Tinymce
                    ref="editor"
                    class="naw_Editor"
                    v-model="form.content"
                    :height="600"
                  />
                </div>
              </el-collapse-item>
            
            </el-collapse>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>


</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import Tinymce from "@/components/Tinymce";
export default {
  mixins: [form],
  name: "createForm",
  components: {
    Tinymce,
  },
  data() {
    return {
      activeName: "1",
      serchoInput: "",
      save_loading: false,
      update_loading: false,
      remove_loading: false,
      template: "",
      form: {
        name: "",
        content: "",
        hide_footer: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "formLogo/list",
      categories: "categoryDocumentation/list",
      model: "formLogo/model",
    }),
  },
  watch: {},
  created() {
    if (this.categories.length == 0) {
      this.categoryList();
    }
  },
  methods: {
    ...mapActions({
      updateList: "formLogo/index",
      save: "formLogo/store",
      update: "formLogo/update",
      remove: "formLogo/destroy",
      categoryList: "categoryDocumentation/index",
    }),
    // fetchList(){
    //     this.updateList({}).then(res => {
    //         this.list = res.data.data;
    //     }).catch(err => {
    //         console.log(err);
    //     });
    // },
    saveData() {
      
        this.save_loading = true;
        this.save(this.form)
          .then((res) => {
            this.save_loading = false;
            this.resetForm("form");
            this.parent().listChanged();
            this.$alert(res);
            this.loadModel();
            this.close();
          })
          .catch((err) => {
            this.save_loading = false;
            this.$alert(err);
          });
     
    },
    print() {
      let header =
        this.form.hide_header === true
          ? this.form.content + " <br>"
          : " ";

      let DATA = header + this.form.content;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(DATA);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    close(){
      this.form = {}
    },
    loadModel() {
      this.form = {
        name: "",
       
        content: "",
        hide_header: false,
      };
      this.template = "";
    },
    resetForm(formName) {
      this.$emit("c-close", { drawer: "drawerCreate" });
    },
  },
};
</script>
<style lang="scss">
.naw_Editor {
  .mce-panel {
    border: 0 solid #c5c5c55e;
  }
}
.block_Editor_Naw {
  padding: 20px;
}
</style>